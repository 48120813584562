import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCatalogItemById,
  pushEventsLog,
  saveCart,
  saveProductLoaderId,
  setSelectedVariantModalVisibility,
  setVariantModalVisibility,
} from 'src/redux/actions';
import {
  AmountActual,
  AmountDiscounted,
  CardContainer,
  CardBody,
  ButtonContainer,
  CardData,
  CardDescription,
  CardDiscount,
  CardMain,
  CardPrice,
  CardTitle,
  Button,
  CardTags,
  CardTag,
  CardInventory,
  B2BPriceText,
} from './Card.styles';
import { deviceWidth } from '@/utils/deviceWidth';
import { isNotifyProductEnabled } from '@/utils/isNotifyProductEnabled';
import { checkForLimitedQuantity } from '@/utils/checkForLimitedQuantity';
import { itemQuantityValueComparison } from '@/utils/itemQuantityValueComparison';
import { getStoreType } from '@/utils/getStoreType';
import { getDiscountPercentage } from '@/utils/getDiscountPercentage';
import { toastNotifyInfo } from '@/components/Common/Toast';
import ItemImage from '../../ItemImage';
import { MAX_MOBILE_WIDTH, STORE_CUSTOMIZATION } from '@/utils/constants';
import moment from 'moment';
import { mutateItemObjectWithCartData } from '@/utils/mutateItemObjectWithCartData';
import { useSSRSelector } from '@/redux/ssrStore';
import { useCartHandlers } from '@/hooks/useCartHandler';
import { IS_SERVER } from 'src/utils/checkRenderEnv';

const ProductCardWidget1 = (props) => {
  const {
    data,
    showCategoryCard = false,
    imageUrl = 'https://picsum.photos/300/300',
    noTags = false,
  } = props;

  const dispatch = useDispatch();
  const storeData = useSSRSelector((state) => state.storeReducer.store);
  const { userData, cartData } = useSelector((state) => ({
    userData: state.userReducer,
    cartData: state.cartReducer,
  }));
  const { theme, store_id, premium } = storeData;
  const [discountPercent, setDiscountPercent] = useState(0);
  const {
    setProductData,
    onPersonalizeProductClick,
    handleIncreamentClick,
    handleDecreamentClick,
    handleSetProductCustomizationCount,
  } = useCartHandlers();

  const isB2bStore = getStoreType(storeData) === STORE_CUSTOMIZATION.B2B;

  const itemIndexInCart = cartData.items.findIndex(
    (el) => (el.id || el.item_id) === data?.id
  );

  useEffect(() => {
    if (data) {
      const percent = getDiscountPercentage(data?.price, data?.discounted_price);
      setDiscountPercent(percent ? percent : 0);
    }
  }, [data]);

  const handleAddRemoveItem = (type) => {
    dispatch(
      pushEventsLog({
        event_name: 'Cx_ATC',
        data: {
          store_id: storeData?.store_id,
          domain: storeData?.store_info?.domain,
          page: window.location.href,
          device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
          cx_number: userData?.data?.phone,
        },
      })
    );
    const productItem = cartData.items[itemIndexInCart];
    // managed_inventory !== 0. available_quantity !== 0.
    // don't proceed to control flow if available_quantity is 0
    if (type === 'inc' && itemQuantityValueComparison(productItem)) {
      toastNotifyInfo('No more units available for purchase');
      return;
    }

    /** Check if variant present in this item */
    if (props.data?.variants_count > 0) {
      dispatch(saveProductLoaderId(props.data?.id));

      /** check if item exist in cart */
      if (itemIndexInCart !== -1) {
        setProductData && setProductData(productItem);
        switch (type) {
          case 'inc':
            dispatch(setVariantModalVisibility(true));
            break;
          case 'dec':
            dispatch(setSelectedVariantModalVisibility(true));
            break;
        }
      } else {
        /** get the details item for this item id */
        dispatch(
          fetchCatalogItemById(
            { store_id: store_id, item_id: props.data?.id },
            true,
            (data) => setProductData && setProductData(data)
          )
        );
      }
    } else {
      if (data?.product_personalization) {
        handleSetProductCustomizationCount && handleSetProductCustomizationCount(data);
        setProductData && setProductData(data);
        switch (type) {
          case 'add':
            onPersonalizeProductClick && onPersonalizeProductClick();
            break;
          case 'inc':
            handleIncreamentClick && handleIncreamentClick(type, data);
            break;
          case 'dec':
            handleDecreamentClick && handleDecreamentClick(type, data);
            break;
          default:
            break;
        }
      } else {
        const newState = mutateItemObjectWithCartData(type, data, cartData);
        dispatch(saveCart(newState));
      }
    }
  };

  // function to remove all anchor tags from string
  function removeTagsAndReturn(str) {
    if (IS_SERVER) return str;
    const tmp = document.createElement('div');
    // add string to created div as html
    tmp.innerHTML = str;
    // get all anchor tags added to created element
    const anchor_tag_collection = tmp.getElementsByTagName('a');
    // iterate over all the tags and remove them from the HTML collection
    if (anchor_tag_collection.length) {
      // convert HTML collection to array
      Array.from(anchor_tag_collection).forEach(function (tag) {
        // remove the tags
        if (tag) {
          tag.parentNode.removeChild(tag);
        }
      });
    }
    return tmp.textContent || tmp.innerText || '';
  }

  function handleOnClickCardContainer() {
    if (data?.available || isNotifyProductEnabled()) {
      props.onItemClick && props.onItemClick();
    }
  }

  return (
    <CardContainer
      isAvailable={data?.available}
      isB2bStore={isB2bStore}
      reactSlick={props.reactSlick}
      categoryCard={showCategoryCard}
      imageUrl={data?.thumbnail_url}
      isNotifyProductEnabled={isNotifyProductEnabled()}
      vocab="http://schema.org/"
      typeof="Product"
      style={{
        boxShadow: premium === 0 && theme.is_demo === 0 ? '0 2px 6px #4a4a4a' : '',
      }}
    >
      {data?.brand_name && (
        <span rel="schema:brand">
          <span typeof="schema:Brand">
            <meta property="schema:name" content={data?.brand_name} />
          </span>
        </span>
      )}
      {data?.barcode && <meta property="schema:gtin" content={data?.barcode} />}
      <meta property="schema:productID" content={data?.id} />
      <CardBody>
        <div onClick={handleOnClickCardContainer}>
          <ItemImage categoryConfig={props.categoryConfig} productData={data} />
        </div>
        <CardMain imageUrl={data?.thumbnail_url} padding={premium === 0}>
          <CardData>
            <div className="flex justify-between">
              <div className="flex pr2">
                <CardTitle
                  categoryCard={showCategoryCard}
                  onClick={handleOnClickCardContainer}
                  property="name"
                >
                  {data?.name || 'Available Items'}
                </CardTitle>
              </div>
              {props?.productConfig?.add_to_cart_visibility === true &&
                data?.available === 1 && (
                  <ButtonContainer>
                    <Button
                      disableAddition={itemQuantityValueComparison(
                        cartData.items[itemIndexInCart]
                      )}
                      fontSize="14"
                      onChange={handleAddRemoveItem}
                      quantity={
                        itemIndexInCart >= 0
                          ? cartData.items[itemIndexInCart].quantity
                          : 0
                      }
                    />
                    {props.data?.variants_count > 0 && (
                      <div className="variant--count-icontext">
                        <img src="/assets/images/optionsMultiple.png" />
                        {props.data?.variants_count} options
                      </div>
                    )}
                  </ButtonContainer>
                )}
            </div>
            {!!data?.available && checkForLimitedQuantity(data) && (
              <CardInventory className="highlighter-red-color">
                Limited Stock Left
              </CardInventory>
            )}
            {isB2bStore && (
              <B2BPriceText fontColor={theme?.colors?.primary_color}>
                {data?.b2b_pricing_info?.price_range || 'Price on Request'}
              </B2BPriceText>
            )}
            {!showCategoryCard && !isB2bStore && (
              <div rel="schema:offers">
                <CardPrice typeof="schema:Offer">
                  <meta
                    property="schema:availability"
                    content={`https://schema.org/${
                      data?.available ? 'InStock' : 'OutOfStock'
                    }`}
                  />
                  <AmountDiscounted fontColor={theme?.colors?.primary_color}>
                    <span property="schema:priceCurrency" content="INR">
                      ₹
                    </span>
                    <span property="schema:price">{data?.discounted_price}</span>
                    <meta
                      property="schema:priceValidUntil"
                      content={moment(new Date()).add(1, 'year').format('YYYY-MM-DD')}
                    />
                  </AmountDiscounted>
                  {data?.discounted_price !== data?.price && (
                    <AmountActual>₹{data?.price}</AmountActual>
                  )}
                </CardPrice>
              </div>
            )}
          </CardData>
          {!showCategoryCard && !imageUrl && discountPercent > 0 && (
            <CardDiscount>{`${discountPercent}% OFF`}</CardDiscount>
          )}
        </CardMain>
        {!noTags && data?.tags?.length > 0 && (
          <CardTags padding="0 0 8px">
            {data?.tags?.map(
              (tag) =>
                tag.is_active !== 0 && (
                  <CardTag backgroundColor={tag.tag_color} key={tag.id}>
                    {tag.name}
                  </CardTag>
                )
            )}
          </CardTags>
        )}
        <div className={premium === 0 && 'ph2 pb2'} property="description">
          {!data?.available && !isB2bStore ? (
            <CardDescription>Out of stock</CardDescription>
          ) : (
            !showCategoryCard &&
            data?.description && (
              <CardDescription isAvailable={!!data?.available}>
                {removeTagsAndReturn(data?.description)}
              </CardDescription>
            )
          )}
        </div>
      </CardBody>
    </CardContainer>
  );
};

export default ProductCardWidget1;
